// basic react component
import React from 'react';
import Button from "./Button";

type Props = {
  onClick: () => void;
}

const NavBar: React.FC<Props> = ({onClick}) => {
  return (
    <nav className="bg-white w-full mx-auto px-4 sm:px-6 lg:px-8" >
      <div className="flex justify-between h-16" >
        <div className="flex" >
          <a href="/" className="flex-shrink-0 flex items-center" >
            <img src="logo.png" alt="Your Brand" className="h-12 w-12 mr-2" />
            <span className="font-bold text-xl text-gray-800" >thieu.dev</span >
          </a >
        </div >
        <div className="flex items-center" >
          <Button label={"Join our team"} onClick={onClick} />
        </div >
      </div >
    </nav >
  )
}

export default NavBar;
