/// basic react component
import React from 'react';
import RetroCard from "../RetroCard";
import RetroModal from "../RetroModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const WhoWeAre: React.FC<Props> = ({isOpen, onClose}) => {
  return (
    <RetroModal
      isOpen={isOpen}
      onClose={onClose}
    >
      <RetroCard
        headline="Who we are"
        subheadline="We are here to level up your infrastructure, by turning tech into your business's growth."
        onClose={onClose}
      >
        <p className="text-white mb-4 " >
          We help you build and automate your infrastructure. We strive to be partners in growth and
          innovation.
        </p >
        <p className="text-white mb-4 " >
          Our Approach: Understand the 'why' behind every action. We think, question, and understand before we do.
        </p >
        <p className="text-white " >
          Our goal is to enable our clients to focus on their core business, while we handle or polish the technical
          complexities.
        </p >
      </RetroCard >
    </RetroModal >
  );
};

export default WhoWeAre;
