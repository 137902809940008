// RetroCard.tsx
import React from 'react';
import RetroButton from "./RetroButton";

type RetroCardProps = {
  onClose?: () => void;
  headline?: string;
  subheadline?: string;
  description?: string;
  children?: React.ReactNode;
};

const RetroCard: React.FC<RetroCardProps> = ({headline, subheadline, description, onClose, children}) => {

  return (
    <div
      className="font-pixel text-xs overflow-auto pointer-events-auto bg-black bg-opacity-80 text-white border border-blue-500 rounded-lg shadow-lg retro-glow p-4 relative " >
      <div className="flex flex-col justify-between h-full" >
        <div>
          {onClose && (
            <div className="absolute top-2 right-2" >
              <RetroButton onClick={onClose} label="X" />
            </div >
          )}
          {headline && <h2 className="font-pixel text-xl font-bold mb-4" >{headline}</h2 >}
          {subheadline && <h3 className="font-pixel text-xs font-bold mb-4" >{subheadline}</h3 >}
          {description && <p className="text-white">{description}</p >}
          {children}
        </div >
      </div >
    </div >
  );
};

export default RetroCard;
