/// basic react component
import React from 'react';
import RetroModal from "../RetroModal";

import {CORE_VALUES} from "../../constants";
import RetroCardForList from "../RetroCardForList";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const HowWeWorkModal: React.FC<Props> = ({isOpen, onClose: _onClose}) => {
  const onClose = () => {
    _onClose()
  }

  return (
    <RetroModal isOpen={isOpen} onClose={onClose} >
      <RetroCardForList title="How we work" values={CORE_VALUES} onClose={onClose} />
    </RetroModal >
  );
};

export default HowWeWorkModal;
