import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import createScene from "./scene";
import RetroButton from "./components/RetroButton";
import RetroLink from "./components/RetroLink";
import WhoWeAre from './components/modals/WhoWeAre';
import HowWeWorkModal from './components/modals/HowWeWorkModal';
import WhatWeDoModal from './components/modals/WhatWeDoModal';
import FormModal from './components/modals/FormModal';
import NavBar from "./components/NavBar";


function App() {
  const [isWhoWeAreModalOpen, setIsWhoWeAreModalOpen] = useState(false);
  const [isHowWeWorkModalOpen, setIsHowWeWorkModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isWhatWeDoModalOpen, setIsWhatWeDoModalOpen] = useState(false);
  const [isWhatWeDoModalInitialIndex, setIsWhatWeDoModalInitialIndex] = useState(0);

  const closeHowWeWorkModal = () => {
    setIsHowWeWorkModalOpen(false);
  }

  const isModalOpen = isWhatWeDoModalOpen || isFormModalOpen || isWhoWeAreModalOpen || isHowWeWorkModalOpen;

  const refContainer = useRef(null);
  useEffect(() => {
    createScene(refContainer);
    return;
  }, []);
  return (
    <div className="relative" style={{height: '100%'}} >
      <div ref={refContainer} className="h-full" />
      <div className="absolute overflow-hidden pointer-events-none max-h-screen h-full top-0 left-0 w-full" >
        <NavBar onClick={() => setIsFormModalOpen(true)} />
        <section
          style={{height: 'calc(100vh - 10rem)'}}
          className="w-full md:max-w-md lg:max-w-xl my-auto mx-auto px-4 sm:px-6 lg:px-8 flex justify-center items-center" >
          {!isModalOpen && (<div className="container" >
              <div className="mb-4" >
                <p className=" font-pixel text-xl font-bold text-white" >We <RetroLink
                  onClick={() => {
                    setIsWhatWeDoModalInitialIndex(2);
                    setIsWhatWeDoModalOpen(true);
                  }} label={"Build"} /> and <RetroLink label={"Automate"}
                                                                                              onClick={() => setIsWhatWeDoModalOpen(true)} />.
                </p >
              </div >
              {!isModalOpen && (<div className="mb-4" >
                <p className="font-pixel text-sm font-bold text-white" style={{boxShadow: "0 0 0"}} >DevOps Solutions
                  and
                  Outsourcing for Your Business.</p >
              </div >)}
              <div className="mb-4 " >
                <RetroButton hidden={isModalOpen} label="Who we are" onClick={() => setIsWhoWeAreModalOpen(true)} />
              </div >
              <div className="mb-4 " >
                <RetroButton hidden={isModalOpen} label="How we work"
                             onClick={() => setIsHowWeWorkModalOpen(true)} />
              </div >
              <div className="mb-4" >
                <RetroButton hidden={isModalOpen} label="What we do"
                             onClick={() => setIsWhatWeDoModalOpen(true)} />
              </div >
              <div className="" >
                <RetroButton hidden={isModalOpen} label="Contact us"
                             onClick={() => setIsFormModalOpen(true)} />
              </div >
            </div >
          )}
        </section >
        <WhoWeAre isOpen={isWhoWeAreModalOpen} onClose={() => setIsWhoWeAreModalOpen(false)} />
        <HowWeWorkModal isOpen={isHowWeWorkModalOpen} onClose={closeHowWeWorkModal} />
        <WhatWeDoModal initialIndex={isWhatWeDoModalInitialIndex} isOpen={isWhatWeDoModalOpen} onClose={() => {
          setIsWhatWeDoModalInitialIndex(0);
          setIsWhatWeDoModalOpen(false);
        }} />
        <FormModal isOpen={isFormModalOpen} onClose={() => setIsFormModalOpen(false)} />
      </div >
    </div >
  );
}


export default App;
