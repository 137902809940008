/// basic react component
import React from 'react';
import RetroModal from "../RetroModal";
import RetroCardForList from "../RetroCardForList";
import {PACKAGES} from "../../constants";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  initialIndex?: number;
}

const WhatWeDoModal: React.FC<Props> = ({initialIndex, isOpen, onClose}) => {
  return (
    <RetroModal
      isOpen={isOpen}
      onClose={onClose}
    >
      <RetroCardForList initialIndex={initialIndex} title={"What we do"} isOneItemPerPage values={PACKAGES} onClose={onClose}/>
    </RetroModal >
  );
};

export default WhatWeDoModal;
