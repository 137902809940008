// RetroLink component
import React from 'react';

type RetroLinkProps = {
  label: string;
  onClick?: () => void;
  href?: string;
  hidden?: boolean;
  type?: "button" | "submit" | "reset";
};

const RetroLink: React.FC<RetroLinkProps> = ({href, label, onClick, hidden}) => {
  if (hidden) {
    return null;
  }
  return (
    <a href={href} >
      <button onClick={onClick} className="pointer-events-auto retro-glow underline underline-offset-4 transform transition duration-150 ease-in-out hover:scale-110 active:scale-90" style={{boxShadow: "0 0 0"}}>
        {label}
      </button >
    </a>
  );
};

export default RetroLink
