// RetroCardForList
import React, {useEffect, useState} from 'react';
import RetroCard from "./RetroCard";
import RetroPaginator from "./RetroPaginator";

interface Props {
  title: string,
  isOneItemPerPage?: boolean;
  values: {
    title: string;
    description: string | string[];
  }[];
  onClose: () => void;
  initialIndex?: number;
}

const RetroCardForList: React.FC<Props> = ({initialIndex, title, values, onClose, isOneItemPerPage}) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex || 0);
  const [valuesPerPage, setValuesPerPage] = useState(isOneItemPerPage ? 1 : 2);
  const [currentValues, setCurrentValues] = useState(values);

  const totalPages = Math.ceil(currentValues.length / valuesPerPage);
  const currentPage = Math.floor(currentIndex / valuesPerPage) + 1;

  const nextValues = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + valuesPerPage, currentValues.length - valuesPerPage));
  };

  const previousValues = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - valuesPerPage, 0));
  };

  const displayedValues = currentValues.slice(currentIndex, currentIndex + valuesPerPage);

  useEffect(() => {
    function getValuesPerPage() {
      if (isOneItemPerPage) return 1;
      const screenWidth = window.innerWidth;
      if (screenWidth < 640) { // sm breakpoint
        return 2;
      } else {
        return 3;
      }
    }
    const handleResize = () => {
      setValuesPerPage(getValuesPerPage());
      setCurrentValues(values.length % getValuesPerPage() === 0 ? values : [...values, {
        title: '',
        description: ''
      }])
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [values, isOneItemPerPage]);
  return (
    <RetroCard onClose={onClose} headline={title} >
      {displayedValues.map((value, index) => (
        <div key={index} className="value-section mb-4" >
          <h3 className="font-bold text-sm mb-2" >{value.title}</h3 >
          {
            value.description instanceof Array ?
              value.description.map((description, index) => (
                <p key={index} className={`text-white ${index + 1 < value.description.length ? "mb-2" : ""}`} >{description}</p >
              ))
              : (<p key={index} className="text-white" >{value.description}</p >)
          }
        </div >
      ))}
      <RetroPaginator valuesLength={values.length} nextValues={nextValues} previousValues={previousValues}
                      currentIndex={currentIndex} totalPages={totalPages} currentPage={currentPage}
                      valuesPerPage={valuesPerPage} />
    </RetroCard >
  );
};

export default RetroCardForList;
