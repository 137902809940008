/// basic react component
import React from 'react';
import RetroCard from "../RetroCard";
import RetroForm from '../RetroForm';
import RetroLink from '../RetroLink';
import RetroModal from "../RetroModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const FormModal: React.FC<Props> = ({isOpen, onClose}) => {
  return (
    <RetroModal
      isOpen={isOpen}
      onClose={onClose}
    >
      <RetroCard headline={"Contact us"}
                 onClose={onClose} >
        <p className="text-white  mb-4" >Send us an email at <RetroLink label={"contact@thieu.dev"}
                                                                  href="mailto:contact@thieu.dev" /></p >
        <RetroForm />
      </RetroCard >
    </RetroModal >
  );
};

export default FormModal;
