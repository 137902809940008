import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

let isCreated = false;
let userCanInteract = false;

let zoomDirection = 1;
const MAX_ZOOM_OUT = 900;
const MIN_ZOOM_OUT = 50;
const ZOOM_SPEED = 0.0016

export default function createScene(container: React.RefObject<HTMLDivElement>) {
  if (isCreated) {
    return;
  }
  isCreated = true;
  // === THREE.JS CODE START ===
  let group: THREE.Group;
  const particlesData: any[] = [];
  let camera: THREE.PerspectiveCamera, scene: THREE.Scene, renderer: THREE.WebGLRenderer;
  let positions, colors;
  let particles: THREE.BufferGeometry;
  let pointCloud: THREE.Points;
  let particlePositions: Float32Array;
  let linesMesh: THREE.LineSegments;

  const maxParticleCount = 200;
  let particleCount = 200;
  const r = 1000;
  const rHalf = r / 2;

  const effectController = {
    showDots: true,
    showLines: true,
    minDistance: 250,
    limitConnections: true,
    maxConnections: 5,
    particleCount: 200,
    velocityFactor: 6,
  };

  init();
  animate();

  const minDistanceRange = {min: 150, max: 250};
  const maxConnectionsRange = {min: 5, max: 10};

  let minDistanceDirection = -1;
  let maxConnectionsDirection = 1;

  function adjustValues() {
    // Adjust minDistance
    effectController.minDistance += minDistanceDirection * 5; // Adjust the step size if needed
    if (effectController.minDistance > minDistanceRange.max || effectController.minDistance < minDistanceRange.min) {
      minDistanceDirection *= -1;
    }

    // Adjust particleCount
    // effectController.particleCount += particleCountDirection * 10; // Adjust the step size if needed
    // if (effectController.particleCount > particleCountRange.max || effectController.particleCount < particleCountRange.min) {
    //   particleCountDirection *= -1;
    // }

    // Adjust maxConnections
    effectController.maxConnections += maxConnectionsDirection * 0.5; // Adjust the step size if needed
    if (effectController.maxConnections > maxConnectionsRange.max || effectController.maxConnections < maxConnectionsRange.min) {
      maxConnectionsDirection *= -1;
    }

    // Update the scene based on new values
    // particles.setDrawRange(0, effectController.particleCount);
  }


  function init() {

    camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 2500);
    camera.position.set(0, MIN_ZOOM_OUT, 0); // Start position
    camera.rotation.x = -Math.PI / 2;


    const controls = new OrbitControls(camera, container.current!);
    controls.minDistance = MIN_ZOOM_OUT;
    controls.maxDistance = MAX_ZOOM_OUT;
    controls.zoomSpeed = 0.14159;
    controls.rotateSpeed = 0.14159;
    controls.enableZoom = true;
    controls.enablePan = false;
    controls.enabled = userCanInteract;


    scene = new THREE.Scene();


    group = new THREE.Group();
    scene.add(group);

    const helper = new THREE.BoxHelper(new THREE.Mesh(new THREE.BoxGeometry(r, r, r)));
    helper.material.color.setHex(0x000);
    helper.material.blending = THREE.AdditiveBlending;
    helper.material.transparent = true;
    group.add(helper);

    const segments = maxParticleCount * maxParticleCount;

    positions = new Float32Array(segments * 3);
    colors = new Float32Array(segments * 3);

    const pMaterial = new THREE.PointsMaterial({
      color: 0xFFFFFF,
      size: 3.14,
      blending: THREE.AdditiveBlending,
      transparent: true,
      sizeAttenuation: true
    });

    particles = new THREE.BufferGeometry();
    particlePositions = new Float32Array(maxParticleCount * 3);

    for (let i = 0; i < maxParticleCount; i++) {

      const x = Math.random() * r - r / 2;
      const y = Math.random() * r - r / 2;
      const z = Math.random() * r - r / 2;

      particlePositions[i * 3] = x;
      particlePositions[i * 3 + 1] = y;
      particlePositions[i * 3 + 2] = z;

      // add it to the geometry
      particlesData.push({
        velocity: new THREE.Vector3((-1 + Math.random() * 2) / effectController.velocityFactor, (-1 + Math.random() * 2) / effectController.velocityFactor, (-1 + Math.random() * 2) / effectController.velocityFactor),
        numConnections: 0
      });

    }

    particles.setDrawRange(0, particleCount);
    particles.setAttribute('position', new THREE.BufferAttribute(particlePositions, 3).setUsage(THREE.DynamicDrawUsage));

    // create the particle system
    pointCloud = new THREE.Points(particles, pMaterial);
    group.add(pointCloud);

    const geometry = new THREE.BufferGeometry();

    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3).setUsage(THREE.DynamicDrawUsage));
    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3).setUsage(THREE.DynamicDrawUsage));

    geometry.computeBoundingSphere();

    geometry.setDrawRange(0, 0);

    const material = new THREE.LineBasicMaterial({
      vertexColors: true,
      blending: THREE.AdditiveBlending,
      transparent: true
    });

    linesMesh = new THREE.LineSegments(geometry, material);
    group.add(linesMesh);

    renderer = new THREE.WebGLRenderer({antialias: true});
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);

    container.current!.appendChild(renderer.domElement);

    window.addEventListener('resize', onWindowResize);

    setInterval(adjustValues, 250);
  }

  function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);

  }

  function animate() {

    let vertexpos = 0;
    let colorpos = 0;
    let numConnected = 0;

    for (let i = 0; i < particleCount; i++)
      particlesData[i].numConnections = 0;

    for (let i = 0; i < particleCount; i++) {
      // get the particle
      const particleData = particlesData[i];

      particlePositions[i * 3] += particleData.velocity.x;
      particlePositions[i * 3 + 1] += particleData.velocity.y;
      particlePositions[i * 3 + 2] += particleData.velocity.z;

      if (particlePositions[i * 3 + 1] < -rHalf || particlePositions[i * 3 + 1] > rHalf)
        particleData.velocity.y = -particleData.velocity.y;

      if (particlePositions[i * 3] < -rHalf || particlePositions[i * 3] > rHalf)
        particleData.velocity.x = -particleData.velocity.x;

      if (particlePositions[i * 3 + 2] < -rHalf || particlePositions[i * 3 + 2] > rHalf)
        particleData.velocity.z = -particleData.velocity.z;

      if (effectController.limitConnections && particleData.numConnections >= effectController.maxConnections)
        continue;

      // Check collision
      for (let j = i + 1; j < particleCount; j++) {

        const particleDataB = particlesData[j];
        if (effectController.limitConnections && particleDataB.numConnections >= effectController.maxConnections)
          continue;

        const dx = particlePositions[i * 3] - particlePositions[j * 3];
        const dy = particlePositions[i * 3 + 1] - particlePositions[j * 3 + 1];
        const dz = particlePositions[i * 3 + 2] - particlePositions[j * 3 + 2];
        const dist = Math.sqrt(dx * dx + dy * dy + dz * dz);

        if (dist < effectController.minDistance) {

          particleData.numConnections++;
          particleDataB.numConnections++;

          const alpha = 1.0 - dist / effectController.minDistance;

          positions[vertexpos++] = particlePositions[i * 3];
          positions[vertexpos++] = particlePositions[i * 3 + 1];
          positions[vertexpos++] = particlePositions[i * 3 + 2];

          positions[vertexpos++] = particlePositions[j * 3];
          positions[vertexpos++] = particlePositions[j * 3 + 1];
          positions[vertexpos++] = particlePositions[j * 3 + 2];

          colors[colorpos++] = alpha;
          colors[colorpos++] = alpha;
          colors[colorpos++] = alpha;

          colors[colorpos++] = alpha;
          colors[colorpos++] = alpha;
          colors[colorpos++] = alpha;

          numConnected++;

        }

      }

      if (!userCanInteract) {
        camera.position.y += zoomDirection * ZOOM_SPEED;
        if (camera.position.y > MAX_ZOOM_OUT) { // Set a max limit for zoom out
          camera.position.y = MAX_ZOOM_OUT;
          zoomDirection = -1;
        }
        if (camera.position.y < MIN_ZOOM_OUT) { // Set a min limit for zoom out
          camera.position.y = MIN_ZOOM_OUT;
          zoomDirection = 1;
        }
      }
    }

    linesMesh.geometry.setDrawRange(0, numConnected * 2);
    linesMesh.geometry.attributes.position.needsUpdate = true;
    linesMesh.geometry.attributes.color.needsUpdate = true;

    pointCloud.geometry.attributes.position.needsUpdate = true;

    requestAnimationFrame(animate);

    render();
  }

  function render() {
    const time = Date.now() * 0.001;

    group.rotation.y = time * 0.1;
    renderer.render(scene, camera);

  }
}
