export const PACKAGES = [
  {
    title: "DevOps Revamp",
    description: [
      "For startups and businesses in need of upgrading their existing infrastructure.",
      "We help you build a DevOps culture and implement the right tools and processes for your organization.",
      "Customized pipeline setup, cloud integration, continuous integration/continuous deployment (CI/CD), monitoring, and automated testing.",
      "We also provide ongoing support and maintenance to ensure your infrastructure is always up-to-date and secure."
    ]
  },
  {
    title: "Blockchain Consulting",
    description: [
      "For ventures looking to leverage blockchain technology to build web3 solutions.",
      "We help you understand the potential of blockchain and how it can be applied to your business.",
      "We offer R&D services, feasibility studies, and proof-of-concept development to help you validate your ideas.",
      "We enable you to harness the full potential of blockchain technology, resulting in a more secure, transparent, and efficient business."
    ]
  },
  {
    title: "Craft Your Product",
    description: [
      "Transform your vision into a market-ready product with our comprehensive approach.",
      "We work with you to define your product’s scope and create a roadmap to go to market.",
      "From concept to launch, we help handle strategic planning, UX/UI design, scaling robust architectures, and driving growth through analytics.",
      "We simplify the journey of product development, ensuring that your product is aligned with your business goals and user needs.",
    ]
  },
];
export const CORE_VALUES = [
  {
    title: "Start with the Why",
    description: "Every project begins with understanding its purpose. We encourage curiosity and a deep dive into the reasons behind our work, ensuring that each effort is impactful and relevant."
  },
  {
    title: "Take Initiative",
    description: "In our dynamic culture, taking the lead is valued. We empower our employees to act on their ideas and insights, creating a culture of innovation and continuous improvement."
  },
  {
    title: "Embrace Resilience",
    description: "In the fast-paced world of technology, adaptability and resilience are key. We embrace change and view challenges as opportunities to grow and innovate."
  },
  {
    title: "Speak Up",
    description: "We believe in the power of collaborative efforts and open communication. Every voice is heard, and every idea is valued, creating a rich tapestry of perspectives that drive our company forward."
  },
  {
    title: "Think Critically",
    description: "We foster an environment where questioning the status quo is the norm. Our team members are expected to analyze, evaluate, and create rather than just follow instructions."
  },
  {
    title: "Be Data-Driven",
    description: "We believe that leveraging data is crucial in every day-to-day action. This ensures that our solutions are decisions grounded in empirical evidence, optimizing performance and delivering tangible results for our clients."
  },
  {
    title: "Endeavor Learning",
    description: "Personal and professional growth are at the core of our company. We invest in our team’s development and encourage a mindset of lifelong learning and adaptability."
  },
  {
    title: "Cultivate Understanding",
    description: "Understanding and respecting diverse perspectives is crucial. We cultivate an empathetic work environment where respect for each other’s views and life experiences is paramount."
  }
];
