// RetroCard.tsx
import React from 'react';
import RetroButton from './RetroButton';


const RetroForm = () => {
  return (
    <form name="contact" method="post" className="space-y-4" >
      <input type="hidden" name="form-name" value="contact" />
      <div >
        <label className="block text-sm font-bold mb-2" >Your Name:</label >
        <input type="text" name="name"
               className="w-full p-2 rounded-lg bg-black bg-opacity-50 border border-blue-300 focus:border-blue-500 focus:outline-none" />
      </div >
      <div >
        <label className="block text-sm font-bold mb-2" >Your Email:</label >
        <input type="email" name="email"
               className="w-full p-2 rounded-lg bg-black bg-opacity-50 border border-blue-300 focus:border-blue-500 focus:outline-none" />
      </div >
      <div >
        <label className="block text-sm font-bold mb-2" >Message:</label >
        <textarea name="message"
                  className="w-full p-2 rounded-lg bg-black bg-opacity-50 border border-blue-300 focus:border-blue-500 focus:outline-none" ></textarea >
      </div >
      <div >
        <RetroButton type="submit" label="Send" />
      </div >
    </form >
  );
};

export default RetroForm;
