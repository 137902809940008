import React from 'react';

interface ButtonProps {
  label: string;
  onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({label, onClick}) => {
  return (
    <button
      onClick={onClick}
      className="text-sm pointer-events-auto w-full text-blue-500 font-bold py-2 px-4 border-2 border-blue-500 hover:text-blue-700 hover:border-blue-700 rounded-lg transform transition duration-150 ease-in-out hover:scale-105 active:scale-95"
    >
      {label}
    </button >
  );
};

export default Button;
