// RetroModal.tsx
import React from 'react';

type RetroModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

const RetroModal: React.FC<RetroModalProps> = ({isOpen, onClose, children}) => {
  if (!isOpen) return null;

  return (
    <div className="absolute inset-0 flex justify-center items-center" >
      <div className="max-w-sm mx-auto  md:max-w-2xl" >
        {children}
      </div >
    </div >
  );
};

export default RetroModal;
