// RetroPaginator component
import React from 'react';
import RetroButton from "./RetroButton";

type Props = {
  previousValues: () => void;
  nextValues: () => void;
  currentIndex: number;
  totalPages: number;
  currentPage: number;
  valuesPerPage: number;
  valuesLength: number;
};

export default function RetroPaginator({previousValues, nextValues, currentIndex, totalPages, currentPage, valuesPerPage, valuesLength}: Props) {
  return (
    <div className="flex justify-between items-center mt-4" >
      <div style={{width: '100px'}} >
        <RetroButton label="<" onClick={previousValues} hidden={currentIndex === 0} />
      </div >
      <div>
        <span className="text-white">{`[ ${currentPage} | ${totalPages} ]`}</span>
      </div>
      <div style={{width: '100px'}} >
        <RetroButton label=">" onClick={nextValues}
                     hidden={currentIndex >= valuesLength - valuesPerPage} />
      </div >
    </div >
  )
}
